import React from "react"
import { Container, Row, Col, Badge } from "reactstrap"

import Layout from "src/components/Layout"
import Seo from "src/components/Seo"
import Heading from "src/components/index/Heading"
import TestimonialCard from "src/components/TestimonialCard"

import angieAvatar from "src/assets/img/testimonials/angie.jpg"

const Page = () => {
  return (
    <Layout>
      <Seo
        title="Carebit: Migrating to Carebit"
        pathname="/migrating-to-carebit"
        description="If you are thinking of migrating practice management software to Carebit, we can help you move all your data across."
      />
      <div className="u-centerFlex u-textCenter Hero">
        <Container>
          <Row>
            <Col
              lg={{ size: 6, offset: 3 }}
              md={{ size: 10, offset: 1 }}
              style={{ zIndex: 2, padding: "120px 40px 200px", color: "white" }}
            >
              <Badge color="info" className="u-marginBottom20">
                New customers
              </Badge>
              <h1>Migrating to Carebit</h1>
              <p className="u-whiteTranslucent">
                If you already use existing practice management software like
                DGL, Semble, PPM, Specpack, Writeupp, Cliniko, Pabau or another
                system - or even have your own folder of patient files - we can
                help you move your data across seamlessly.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col md={{ size: 12 }}>
              <section>
                <Row className="u-centerFlex">
                  <Col md={{ size: 6 }} className="SplitContentRow-text">
                    <Row>
                      <Heading title="Seamless migration">
                        Modernise your practice by moving to Carebit
                      </Heading>
                    </Row>
                    <p className="u-midGrey">
                      We’ve successfully migrated many practices from their old
                      system to Carebit, bringing across hundreds of thousands
                      of bookings, invoices, patients records, letters and much
                      more. If you are with a different software provider or
                      even use your own in-house system like Google Drive, we
                      can handle that too.
                    </p>

                    <p>
                      Depending on the volume of data, this can often be done in
                      a day - resulting in a seamless switch for your practice.
                      If you’re considering migrating to Carebit and would like
                      to take advantage of this service, please get in touch
                      below to discuss your migration requirements.
                    </p>
                  </Col>
                  <Col
                    lg={{ size: 6 }}
                    md={{ size: 12 }}
                    className="SplitContentRow-graphic"
                  >
                    <TestimonialCard
                      author="Mrs Angie Newman"
                      authorSubtext="Practice manager for a leading London paediatrics practice"
                      avatarSrc={angieAvatar}
                    >
                      <p className="u-midGrey">
                        “Moving from DGL was always going to be a daunting task,
                        change is generally scary and even when something is not
                        providing the service you require, the thought of the
                        unknown is often worse than the inadequate system you
                        are currently working with. So making the decision to
                        move to Carebit was quite a big one to make.
                      </p>

                      <p className="u-midGrey">
                        Our initial migration was a worrying time, but it
                        needn't have been as it was a seamless experience.
                        Carebit handled everything. We were told it would happen
                        on one particular day, and a couple of days later we
                        were up and running in an entirely different system, and
                        it was all done for us!”
                      </p>
                    </TestimonialCard>
                  </Col>
                </Row>
              </section>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default Page
